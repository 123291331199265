import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';


import { Provider } from "react-redux";
import store from "./store/store";

import App from "./App";
import "./index.css";

const container = document.getElementById('root');


const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(

<Provider store={store}>
    <App />
  </Provider>,
);


 
